// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-aziende-page-js": () => import("./../../../src/templates/aziende-page.js" /* webpackChunkName: "component---src-templates-aziende-page-js" */),
  "component---src-templates-beato-page-js": () => import("./../../../src/templates/beato-page.js" /* webpackChunkName: "component---src-templates-beato-page-js" */),
  "component---src-templates-benefici-page-js": () => import("./../../../src/templates/benefici-page.js" /* webpackChunkName: "component---src-templates-benefici-page-js" */),
  "component---src-templates-cookies-page-js": () => import("./../../../src/templates/cookies-page.js" /* webpackChunkName: "component---src-templates-cookies-page-js" */),
  "component---src-templates-cosa-facciamo-page-js": () => import("./../../../src/templates/cosa-facciamo-page.js" /* webpackChunkName: "component---src-templates-cosa-facciamo-page-js" */),
  "component---src-templates-fondazione-page-js": () => import("./../../../src/templates/fondazione-page.js" /* webpackChunkName: "component---src-templates-fondazione-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-logo-page-js": () => import("./../../../src/templates/logo-page.js" /* webpackChunkName: "component---src-templates-logo-page-js" */),
  "component---src-templates-pagina-evento-js": () => import("./../../../src/templates/pagina-evento.js" /* webpackChunkName: "component---src-templates-pagina-evento-js" */),
  "component---src-templates-privacy-page-js": () => import("./../../../src/templates/privacy-page.js" /* webpackChunkName: "component---src-templates-privacy-page-js" */),
  "component---src-templates-progetto-page-js": () => import("./../../../src/templates/progetto-page.js" /* webpackChunkName: "component---src-templates-progetto-page-js" */),
  "component---src-templates-promotori-page-js": () => import("./../../../src/templates/promotori-page.js" /* webpackChunkName: "component---src-templates-promotori-page-js" */),
  "component---src-templates-search-page-js": () => import("./../../../src/templates/search-page.js" /* webpackChunkName: "component---src-templates-search-page-js" */),
  "component---src-templates-sostienici-page-js": () => import("./../../../src/templates/sostienici-page.js" /* webpackChunkName: "component---src-templates-sostienici-page-js" */)
}

