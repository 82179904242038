module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 500px)","sm":"(max-width: 700px)","smm":"(max-width: 765px)","md":"(max-width: 1023px)","lg":"(max-width: 1159px)","l":"(max-width: 1439px)","portrait":"(orientation: portrait)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/home/runner/work/fondazione-acutis__web-site/fondazione-acutis__web-site/src/intl","languages":["it","en","es"],"defaultLanguage":"it","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
