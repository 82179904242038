/* eslint-disable operator-linebreak */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
export function onInitialClientRender(_, pluginOptions) {
  switch (process.env.NODE_ENV) {
    case 'staging': {
      // eslint-disable-next-line global-require,@typescript-eslint/no-var-requires
      // const { default: worker } = require('./src/mocks/browser');
      // worker.start({
      //  serviceWorker: {
      // Points to the custom location of the Service Worker file.
      //   url: '/frontend-framework-team/boilerplate-gatsby/mockServiceWorker.js',
    // },
      // });
      break;
    }
    case 'development': {
      // eslint-disable-next-line global-require,@typescript-eslint/no-var-requires
      // const { default: worker } = require('./src/mocks/browser');
      // worker.start();
      break;
    }
    default:
  }
}

// export function onRouteUpdate(_, pluginOptions) {
//   if (
//     process.env.NODE_ENV === 'production' ||
//     pluginOptions.includeInDevelopment
//   ) {
//     // wrap inside a timeout to ensure the title has properly been changed
//     setTimeout(() => {
//       const data = pluginOptions.dataLayerName
//         ? window[pluginOptions.dataLayerName]
//         : window.dataLayer;
//       const eventName = pluginOptions.routeChangeEventName
//         ? pluginOptions.routeChangeEventName
//         : 'gatsby-route-change';
//       data.push({
//         event: eventName,
//       });

//       if (pluginOptions.location && pluginOptions.location.state) {
//         pluginOptions.location.state.referrer = prevLocation
//           ? prevLocation.pathname
//           : null;
//       }
//     }, 50);
//   }
// }
